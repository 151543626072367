class DFTParameterUtils {

  getNodeFromParams(params) {
    let returnNode = null;
    // @param {node} element
    if (typeof params == 'object') {
      if (typeof params.element == 'object') {
        // We most likely dealind with node
        if (typeof params.element.nodeType == 'number' && params.element.nodeType == 1) {
          // This is a vanillaJS node
          returnNode = $(params.element);
        } 
        // Check if this has been selected using jQuery
        else if (typeof params.element[0] == 'object' && typeof params.element[0].nodeType == 'number' && params.element[0].nodeType == 1) {
          // Node selected using jQuery
          returnNode = params.element;
        }
      } 
      // Check if instead of node, simple selector has been passed
      // @param {string} selector
      else if (typeof params.selector == 'string') {
        returnNode = $(params.selector);
      }      
    }
    return returnNode;
  }
}

module.exports = DFTParameterUtils;