const DFTForm = require('./submodules/DFTForm');
/**
 * DFTFormValidator class
 */
class DFTFormValidator {

  /**
   * Constructor for DFTFormValidator
   * @param object configuration with parameters for vlaidation
   */
  constructor(params) {
    // Stores custom parameters passed to the object
    this._rawCustomParams;
    // Holds default parameters for form validator
    this._defaultParams = {
      // Defines whether to validate the form 'on ready' or not
      validateOnReady: false,
      // Defines whether to output errors on ready or not
      outputErrorsOnRady: false
    };
    // Once custom and default parameters are processed, use this object to keep final params
    this._params = {};
    // Holds an instance of the form
    this._form;
    // Process parameters
    this.processParams(params);
  }

  /**
   * Method processes parameters passed to the validator
   */
  processParams(params) {
    if (typeof params == 'object') {
      // Handle required parameters first
      // @param {object} form
      if (typeof params.form == 'object' ) {
        this._form = new DFTForm(params.form);
      } else {
        console.error('Required configuration object for a form is missing.');
        return;
      }

      // @param {boolean} validateOnReady
      // TODO

      // @param {boolean} outputErrorsOnRady
      // TODO

      // @param {function} defaultOnFieldInvalid
      if (typeof params.defaultOnFieldInvalid == 'function') {
        this.setDefaultOnFieldInvalid(params.defaultOnFieldInvalid);
      }

      // @param {function} defaultOnFieldValid
      if (typeof params.defaultOnFieldValid == 'function') {
        this.setDefaultOnFieldValid(params.defaultOnFieldValid);
      }

      // Merge all parameters to the reference object
      Object.assign(this._params, this._defaultParams, params);

      // Assign raw params to the variable once processed
      this._rawCustomParams = params;

    } else {
      console.error(`Parameters passed to the validator must be an object.`);
      return false;
    }
  }

  /**
   * Initializes validator
   */
  init() {
    if (this.getParam('validateOnReady')) {
      this.validateForm();
    }
  }

  validateForm() {
    this._form.isValid();
  }

  /**
   * Retrieves parameter value based on the key
   * @param string name
   */
  getParam(name) {
    return this._params[name];
  }

  /**
   * Sets custom parameters on the class
   * @param object params
   */
  setCustomParameters(params) {}

  setDefaultOnFieldInvalid(handler) {
    this._form.setDefaultOnFieldInvalid(handler);
  }

  setDefaultOnFieldValid(handler) {
    this._form.setDefaultOnFieldValid(handler);
  }
}

module.exports = DFTFormValidator;
