/**
 * DFTValidatorBase
 */
class DFTValidatorBase {

  constructor(params) {
    this._rawParams = params;
    this._params = {};

    // @param {string} errorMsg
    if (typeof this._rawParams.errorMsg == 'string') {
      this._params.errorMsg = this._rawParams.errorMsg;
    }
  }

  getErrorMsg() {
    return this.getParam('errorMsg');
  }

  getParam(name) {
    return this._params[name];
  }

  setErrorMsg() {
    if (!this.getParam('errorMsg')) {
      // Fallback to the default
      this._params.errorMsg = this._defaultParams.errorMsg;
    }
  }

}
module.exports = DFTValidatorBase;