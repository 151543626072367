const DFTParameterUtils = require('./DFTParameterUtils');
const DFTValidatorBase = require('./validators/DFTValidatorBase');
const DFTValidatorRequired = require('./validators/DFTValidatorRequired');
const DFTValidatorLength = require('./validators/DFTValidatorLength');
const DFTValidatorRegex = require('./validators/DFTValidatorRegex');
const DFTValidatorNumber = require('./validators/DFTValidatorNumber');
/**
 * DFTFormField class
 */
class DFTFormField {
  /**
   * constructor for DFTFormField
   */
  constructor(params) {
    this._fieldNode;
    this._validators = [];
    this._required = false;
    this._isValid = false;
    // Custom parameters for the form
    this._rawCustomParams;
    this._defaultParams = {}
    this._params = {};
    this._name = null;
    this._validationErrorMsg;
    this._fieldInvalidHandler = this.fieldInvalidHandler;
    this._fieldValidHandler = this.fieldValidHandler;
    this._paramUtils = new DFTParameterUtils();
    // Holds events that will trigger the validation
    this._validateOn = 'blur';
    this.processParams(params);
    this.buildValidatorsFromParams();
    this.bindValidation();
  }
  /**
   * Process parameters
   */
  processParams(params) {
    // REQUIRED PARAMETERS
    this._fieldNode = this._paramUtils.getNodeFromParams(params);
    if (!this._fieldNode || this._fieldNode.length == 0) {
      console.error("Unable to find requested field", params);
    }

    // Set the name once node is selected
    this._name = $(this._fieldNode).attr('name');

    // OPTIONAL PARAMETERS
    // @param {string} validateOn
    if (typeof params.validateOn == 'string') {
      this._validateOn = params.validateOn;
    }

    this._rawCustomParams = params;
    Object.assign(this._params, this._defaultParams, this._rawCustomParams);
  }
  /**
   * Gets the parameter from parameters object
   * @param {string} name representing paramter name
   */
  getParam(name) {
    return this._params[name];
  }

  getElement() {
    return this._fieldNode;
  }

  getName() {
    return this._name;
  }

  fieldValidHandler() {
    $(this._fieldNode).removeClass('invalid');
    $(this._fieldNode).addClass('valid');
    if ($(this._fieldNode).next().prop('tagName') == 'SPAN') {
      $(this._fieldNode).next().remove();
    }
  }

  fieldInvalidHandler() {
    $(this._fieldNode).removeClass('valid');
    $(this._fieldNode).addClass('invalid');
    if ($(this._fieldNode).next().prop('tagName') == 'SPAN') {
      $(this._fieldNode).next().remove();
    }
    $(this._fieldNode).after(`<span class="error">${this._validationErrorMsg}</span>`);
  }

  setFieldInvalidHandler(handler) {
    this._fieldInvalidHandler = handler;
  }

  setFieldValidHandler(handler) {
    this._fieldValidHandler = handler;
  }

  bindValidation() {
    $(this._fieldNode).on(this._validateOn, () => {
      this.runValidation();
      if (this.isValid()) {
        this._fieldValidHandler(this);
      } else {
        this._fieldInvalidHandler(this);
      }
    });
  }

  isValid() {
    return this._isValid;
  }

  isRequired() {
    return this._required;
  }
  /**
   * Returns field value
   */
  getFieldValue() {
    return $(this._fieldNode).val();
  }
  hasValue() {
    return (this.getFieldValue() && this.getFieldValue() !== '');
  }
  /**
   * Adds validator object to validatros array
   */
  addValidator(validator) {
    this._validators.push(validator);
  }
  /**
   * This method goes through every validator set on the field
   */
  runValidation() {
    let breakValidation = false;
    this._isValid = true;

    // We only need to run validation if value is provided
    // and if the field is required
    if (!this.hasValue() && !this.isRequired()) {
      // Field is not required so no need to run validation
      breakValidation = true;
    }

    $(this._validators).each((i, validator) => {
      if (!breakValidation){
        if (!validator.isValid(this.getFieldValue())) {
          this._isValid = false;
          this._validationErrorMsg = validator.getErrorMsg();
          breakValidation = true;
        }
      }
    });
  }
  /**
   * Gets the validation error message
   */
  getErrorMsg() {
    return this._validationErrorMsg;
  }
  /**
   * Builds validator objects from parameters
   */
  buildValidatorsFromParams() {
    const self = this;
    $(this.getParam('validators')).each((index, config) => {
      switch (config.type) {
        case 'required':
          // Mark field as required if not previously set 
          if (!this._required) {
            this._required = true;
          }
          self.addValidator(new DFTValidatorRequired(config));
          break;
        case 'optional':
          self.addValidator(new DFTValidatorOptional(config));
          break;
        case 'regex':
          self.addValidator(new DFTValidatorRegex(config));
          break;
        case 'length':
          self.addValidator(new DFTValidatorLength(config));
          break;
        case 'isNumber':
          self.addValidator(new DFTValidatorIsNumber(config));
          break;
        case 'number':
          self.addValidator(new DFTValidatorNumber(config));
          break;
      }
    });
  }
  
}

module.exports = DFTFormField;