const DFTValidatorBase = require('./DFTValidatorBase');
/**
 * DFTValidatorRegex
 */
class DFTValidatorRegex extends DFTValidatorBase {

  constructor(params) {
    super(params);
    this._defaultParams = {
      regex: new RegExp(),
      errorMsg: "This field length must be at least 1 character long."
    };
    this.processParams(params);
  }

  processParams(params) {
    this.setErrorMsg();
    // This validator must come with Regex 
    if (params.regex && params.regex instanceof RegExp) {
      this._params.regex = params.regex;
    } else {
      console.error('Regex validator must be passed RegExp instance in regex parameter.');
      return;
    }
  }

  isValid(value) {
    return this._params.regex.test(value);
  }
}

module.exports = DFTValidatorRegex;