const DFTValidatorBase = require('./DFTValidatorBase');
/**
 * DFTValidatorLength
 */
class DFTValidatorLength extends DFTValidatorBase {

  constructor(params) {
    super(params);
    this._defaultParams = {
      min: 1,
      max: null,
      errorMsg: "This field length must be at least 1 character long."
    };
    this.processParams(params);
  }

  processParams(params) {
    this.setErrorMsg();
    // Length takes two arguments
    // @param {number} min
    if (typeof params.min == 'number') {
      this._params.min = params.min;
    } else {
      this._params.min = this._defaultParams.min;
    }
    
    // @param {number} max
    if (typeof params.max == 'number') {
      this._params.max = params.max;
    } else {
      this._params.max = this._defaultParams.max;
    }

    if (!this.getParam('min') && !this.getParam('max')) {
      console.error('Length Validator cannot have max a min values undefined.');
      return;
    }
  }

  isValid(value) {
    let isValid = false;
    const valLength = parseInt(value.length);
    // Check if we have a minimum value
    if (this.getParam('min')) {
      if (this.getParam('max')) {
        // Check minimum and maximum value
        isValid = (valLength >= this.getParam('min') && valLength <= this.getParam('max'));
      } else {
        // Check only minimum value
        isValid = (valLength >= this.getParam('min'));
      }
    } else if (this.getParam('max')) {
      // Check only maximum value
      isValid = (valLength <= this.getParam('max'));
    } else {
      // Both min and max are not defined
      console.error('Length Validator cannot have max a min values undefined.');
    }
    return isValid;
  }
}

module.exports = DFTValidatorLength;