const DFTValidatorBase = require('./DFTValidatorBase');
/**
 * DFTValidatorRequired
 */
class DFTValidatorRequired extends DFTValidatorBase {

  constructor(params) {
    super(params);
    this._defaultParams = {
      errorMsg: "This field is required."
    };
    this.setErrorMsg();
  }

  isValid(value) {
    return (typeof value !== 'undefined' && value !== null && value !== '');
  }
}

module.exports = DFTValidatorRequired;