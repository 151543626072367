"use strict";
class DFTViewport {
  
  constructor(breakpoints) {
    this.breakpoints = breakpoints;
    this.mobile = false;
    this.landscape = false;
    this.tablet = false;
    this.desktop = false;
    this.current_breakpoint = 'mobile';
    this.window = {
      width: 0,
      height: 0
    };
    this.breakpoint_change_event = document.createEvent("CustomEvent");

    this.init();
  }

  init() {
    this.windowResizeHandler();
    this.setCurrentBreakpoint();
  }

  getWindowSize() {
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;    
  }

  setCurrentBreakpoint() {
    this.getWindowSize();
    let current_breakpoint = this.current_breakpoint,
        new_breakpoint = 'mobile',
        self = this;
    Object.keys(self.breakpoints).forEach(function(key) {
      if (self.window.width < self.breakpoints[key]) {
        // Previously set breakpoint is valid. Break the loop
        return true;
      } else {
        // Current viewport extends or equals to the value of the breakpoint
        new_breakpoint = key;
      }
    });

    self.current_breakpoint = new_breakpoint;
    if(new_breakpoint !== current_breakpoint) {
      self.breakpoint_change_event.initCustomEvent('BreakpointChange', false, false, {
        'from': current_breakpoint,
        'to' : new_breakpoint
      });
      window.dispatchEvent(self.breakpoint_change_event);
    }
  }

  windowResizeHandler() {
    let self = this;
    window.onresize = function() { 
      self.getWindowSize();
      self.setCurrentBreakpoint();
    }
  }

  isMobile() { return this.current_breakpoint == 'mobile'; }
  isLandscape() { return this.current_breakpoint == 'landscape'; }
  isTablet() { return this.current_breakpoint == 'tablet'; }
  isDesktop() { return this.current_breakpoint == 'desktop'; }

  isBelow(breakpoint) {
    return this.window.width < this.breakpoints[breakpoint];
  }

}

module.exports = DFTViewport;