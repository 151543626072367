const DFTFormValidator = require('./dfjstools/modules/formValidator');

export default class ContactForm {
  
  get selector() { return 'form#main-contact-form'; }

  get fieldValidation() {
    return {
      defaultOnFieldInvalid: function(field) {
        $(field.getElement()).next('.feedback').html(field.getErrorMsg());
        $(field.getElement()).removeClass('is-valid');
        $(field.getElement()).addClass('is-invalid');
      },
      defaultOnFieldValid: function(field) {
         $(field.getElement()).next('.feedback').html('');
        $(field.getElement()).removeClass('is-invalid');
        $(field.getElement()).addClass('is-valid');
      },
      form: {
        selector: this.selector,
        fields: [
          {
            name: 'name', selector: 'input[name="name"]',
            validators: [
              {
                type: 'required',
                errorMsg: 'Please provide your name'
              }
            ]
          },
          {
            name: 'email', selector: 'input[name="email"]',
            validators: [
              {
                type: 'required',
                errorMsg: 'Email address is required'
              },
              {
                type: 'regex',
                regex: new RegExp("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z\.]{2,6}$"),
                errorMsg: 'Invalid email address'
              }
            ]
          },
          {
            name: 'message', selector: 'textarea[name="message"]',
            validators: [
              {
                type: 'required',
                errorMsg: 'Please specify your message'
              },
              {
                type: 'length',
                min: 10,
                errorMsg: 'Message must be at least 10 characters long'
              }
            ]
          }
        ]
      }
    }
  }

  constructor() {
    this.contactForm = $(this.selector);
    if (this.contactForm.length == 0) return;
    this.init();
  }

  init() {
    this.bindValidtion();
    var self = this;
    $('button', this.selector).on('click', function() {
      // Disable input
      var $submit = $(this);
      $submit.attr('disabled', true);
      var $formFeedback = $(self.contactForm).parent().find('.form-feedback');
      $('fieldset', self.contactForm).attr('disabled', true);
      $submit.html('Processing...');
      $.ajax({
        type: 'POST',
        url: '/api/contact-form',
        data: JSON.stringify({
          name: $('input[name="name"]').val(),
          email: $('input[name="email"]').val(),
          email: $('input[name="phone"]').val(),
          email: $('input[name="company"]').val(),
          message: $('textarea[name="message"]').val()
        }),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function(response) {
          $formFeedback.removeClass('alert-danger');
          $formFeedback.addClass('alert-success');
          $formFeedback.html('Thank you! Your message has been submitted.<br>A member of our team will get back to you as soon as possible.');
          $('.is-valid', self.contactForm).removeClass('is-valid');
          $(self.contactForm).trigger("reset");
        },
        error: function(response) {
          $formFeedback.removeClass('alert-success');
          $formFeedback.addClass('alert-danger');
          $formFeedback.html('Upppss....something went wrong.<br>Please try again or contact us directly.');
        },
        complete: function() {
          $('fieldset', self.contactForm).attr('disabled', false);
          $submit.html('Submit');          
        }
      });
    });
  }

  bindValidtion() {
    this.formValidator = new DFTFormValidator(this.fieldValidation);
  }

}