const DFTViewport = require('./dfjstools/modules/viewport');

export default class MainHeader {
  
  get selector() { return '#main-header'; }

  constructor() {
    this.headerNode = $(this.selector);
    if ($(this.selector).length == 0 ) return;
    this.init();
  }

  init() {
    this.viewport = new DFTViewport({
      'mobile' : 320,
      'desktop': 992
    });
    $('body').on('click', `${this.selector} .mobile-menu-btn`, (e) => {
      $(this.headerNode).toggleClass('opened');
      if ($(this.headerNode).hasClass('opened')) {
        $('.btn', e.currentTarget).html('Close');
      } else {
        $('.btn', e.currentTarget).html('Menu');
      }
    });

    $('.has-lvl-2', '#main-nav').on('click touch', e => {
      $(e.currentTarget).children('.lvl-2').slideDown();
    });

    $(window).on('BreakpointChange', e => {
      if (e.detail.from == 'mobile') {
        $(this.headerNode).removeClass('opened');
        $(`${this.selector} .mobile-menu-btn .btn`).html('Menu');
      }
    })
  }
}