const DFTParameterUtils = require('./DFTParameterUtils');
const DFTFormField = require('./DFTFormField');

class DFTForm {
  /**
   * Constructor for DFTForm
   */
  constructor(params) {  
    // Will hold an object with all form fields, each being DFTFormField instance
    this._fields = [];
    // Holds the submit button node
    this._submitBtn;
    // This variable will hold the form node
    this._formNode;
    // Set variables for required handlers
    this._formValidHandler = this.handleValidFormEvent;
    this._formInvalidHandler = this.handleInvalidFormEvent;
    // Custom parameters for the form
    this._paramUtils = new DFTParameterUtils();
    this._rawCustomParams;
    this._defaultParams = {
      // Defines if the form will be sent via ajax or not
      ajaxed: false
    }
    this._params = {};

    // Holds the boolean value for the form being either valid or not
    this._isValid = false;

    this.processParams(params);

    this.bindValidation();

    this.runValidation();
  }
  /**
   * Processes parameters passed to the object
   * @param params object with parameters
   */
  processParams(params) {
    if (typeof params == 'object') {

      // REQUIRED PARAMETERS
      this._formNode = this._paramUtils.getNodeFromParams(params);

      // By now, we should have formNode selected
      if (!this._formNode || this._formNode.length == 0) {
        console.error("Requested form cannot be found.", params);
        return;
      }

      // @param {object} fields
      // In order to have validator working, we need to have at least one field with one validator
      if (typeof params.fields == 'object') {
        $(params.fields).each((i, fieldParams) => {
          this._fields.push(new DFTFormField(fieldParams));
        });
      } else {
        // No fields passed to the configuration
        console.error("No fields found in configuration.");
        return;
      }

      // OPTIONAL PARAMETERS
      // @param {object} submitBtn
      this._submitBtn = this._paramUtils.getNodeFromParams(params.submitBtn);

      // If by this time we do not have submitBtn, fall back to the default
      if (!this._submitBtn || this._submitBtn.length == 0) {
        // Custom selector not specified. Use default
        this._submitBtn = $(':input[type="submit"]', this._formNode);
      }

      // Merge all parameters to the reference object
      Object.assign(this._params, this._defaultParams, params);

      return this;
    } else {
      console.error('Form parameters must be an instance of object.');
      return false;
    }
  }
  /**
   * Retrieves parameter value based on the key
   * @param string name
   */
  getParam(name) {
    return this._params[name];
  }
  /**
   * Allows to get the field by name
   * @param {string} name
   */
  getFieldByName(name) {
    let returnField;
    $(this._fields).each((i, field) => {
      if (field._name == name) {
        returnField = field;
        return;
      }
    });
    return returnField;
  }
  /**
   * This method binds validation to the form. It only checks if all fields
   * set on the form are valid or not. It runs validation on 
   */
  bindValidation() {
    this._isValid = true;
    $(this._fields).each((i, field) => {
      // One off check on initial binding
      if (!field.isValid()) {
        this._formInvalidHandler();
        this._isValid = false;
      }
      // Run field validation on blur keyup change and paste
      $(field._fieldNode).on('blur keyup change paste', () => {
        this.runValidation();
      });
    });

    // Now check if the form is valid or not
    if (this.isValid()) {
      this._formValidHandler();
    }
  }

  setDefaultOnFieldInvalid(handler) {
    // @param {function} handler
    if (typeof handler == 'function') {
      $(this._fields).each((i, field) => {
        field.setFieldInvalidHandler(handler);
      });
    }
  }

  setDefaultOnFieldValid(handler) {
    // @param {function} handler
    if (typeof handler == 'function') {
      $(this._fields).each((i, field) => {
        field.setFieldValidHandler(handler);
      });
    }
  }

  runValidation() {
    this._isValid = true;
    $(this._fields).each((i, field) => {
      field.runValidation();
      if (!field.isValid()) {
        this._isValid = false;
        return;
      }
    });  
    if (this.isValid()) {
      this._formValidHandler(this);
    } else {
      this._formInvalidHandler(this);
    }
  }

  handleValidFormEvent() {
    $(this._submitBtn).prop('disabled', false);
  }

  handleInvalidFormEvent() {
    $(this._submitBtn).prop('disabled', true);
  }
  /**
   * Method that triggers when form is submitted
   */
  onSubmit() {}
  /**
   * Boolean method.
   */
  isValid() {
    return this._isValid;
  }
  /**
   * Allows user to set custom onSubmit method
   * @param funciton onSubmit
   */
  setOnSubmit(onSubmit) {
    if (typeof onSubmit == 'function') {

    }
  }

}

module.exports = DFTForm;