const DFTValidatorBase = require('./DFTValidatorBase');
/**
 * DFTValidatorNumber
 */
class DFTValidatorNumber extends DFTValidatorBase {
  
  constructor(params) {
    super(params);
    this._defaultParams = {
      min: null,
      max: null,
      errorMsg: "Please select number greater than 0"
    };
    this.processParams(params);
  }

  processParams(params) {
    this.setErrorMsg();
    this._params = this._defaultParams;
    // This validator takes two configuration parameters
    // @param {number} min
    if (typeof params.min == 'number') {
      this._params.min = params.min;
    }

    // @param {number} max
    if (typeof params.max == 'number') {
      this._params.max = params.max;
    }
  }

  isValid(value) {
    // First of all, check it provided value is a number
    const testVal = parseInt(value);
    if (isNaN(testVal)) {
      return false;
    } else {
      // Check if min and max values are defined
      if (this.getParam('min') !== null) {
        if (this.getParam('max') !== null) {
          return (testVal >= parseInt(this.getParam('min')) && testVal <= parseInt(this.getParam('max')));
        } else {
          return testVal >= parseInt(this.getParam('min'));
        }
      } else if (this.getParam('max') !== null) {
        return testVal <= parseInt(this.getParam('max'));
      } else {
        // Both parameters are null co we jsut check if the value is number
        return true;
      }
    }

  }
}

module.exports = DFTValidatorNumber;